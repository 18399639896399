<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <template v-if="value">
      <span style="word-wrap: break-word">
        <a
            :href="`tel:${item}`"
            :key="index"
            v-for="(item, index) in getValue"
        >
          {{ index === getValue.length - 1 ? item : `${item}, ` }}
        </a>
      </span>
    </template>
    <template v-else>
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </template>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm],
  name: 'Index',
  components: {BaseFormItem},
  computed: {
    getValue() {
      return this.value.split(',').map(item => {
        return item.trim();
      });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>

</style>
